import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Views/Home";
import { NotFound } from "./Views/NotFound";
import Admin from "./Views/Admin";

export const RouteComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
