const Comment = ({text }: any) => {

  return (
    <div className="w-[95%] mb-5 mx-auto">
      <div className="flex gap-5">
        <p className="font-semibold"> Eduardo Sanchez </p>
        <p className="text-gray-400"> August 15, 2024 at 1:08 PM </p>
      </div>
      <p className="mt-3">
        {text}
      </p>
    </div>
  );
};

export default Comment;
