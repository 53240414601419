import { CommentBox } from "../Components/CommentBox";
import { NavBar } from "../Components/NavBar";
import { useMainStore } from "../Zustand/main.store";
import Sidebar from "./Sidebar";

export const Home = () => {
  const pdfSelected = useMainStore((state: any) => state.pdfSelected);

  return (
    <>
      <NavBar />
      {/* Container for Sidebar and Main Content */}
      <div className="flex">
        <Sidebar />
        <div className="flex-1 p-6">
          <div className="h-[600px] mt-6">
            {pdfSelected !== "" ? (
              <iframe
                src={pdfSelected}
                width="100%"
                height="100%"
                title="PDF Viewer"
                style={{ border: "none" }}
              />
            ) : (
              <div>
                <div className="bg-[#333539] w-full h-[50px]"></div>
                <div className="bg-[#525659] h-[550px]"></div>
              </div>
            )}
          </div>
          {/* Comment Box */}
          {pdfSelected !== "" && <CommentBox />}
        </div>
      </div>
    </>
  );
};
