import banner from "../img/banner.png";

const Banner = () => {
  return (
    <div className="w-[100%] ">
      <img src={banner} width={"100%"} height={"100px"} alt="banner" />
    </div>
  );
};

export default Banner;
