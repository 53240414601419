import Banner from "./Banner";

export const NavBar = () => {
  return (
    <>
      <nav className="bg-white p-4 sticky top-0 z-50">
        <div className="container mx-auto flex items-center">
          <div className="flex-grow-0">
            <img
              width="200px"
              src="https://fortiline.reece.com/logos/fortiline.svg"
              alt="logo"
            />
          </div>
          <div className="flex-grow"></div>
          <h3 className="font-bold text-end flex-grow-0">
            Waterworks Documentation Portal
          </h3>
        </div>
      </nav>
      <Banner />
    </>
  );
};
