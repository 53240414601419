import { useState } from "react";
import {
  Select,
  MenuItem,
  List,
  SelectChangeEvent,
  ListItemButton,
  ListItemText,
  createTheme,
  ThemeProvider,
  ListItemIcon,
  TextField,
  Button,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { OpenInNew, PictureAsPdf } from "@mui/icons-material";
import { useMainStore } from "../Zustand/main.store";

const theme = createTheme({
  palette: {
    primary: {
      main: "#193F70",
      light: "#2654C0",
    },
  },
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333539",
    fontSize: theme.typography.pxToRem(16),
  },
}));

type Document = {
  name: string;
  type: "pdf" | "link";
  value: string;
};

const usStates: { [key: string]: string[] } = {
  Alabama: ["Summerdale"],
  Arizona: ["Mesa", "Peoria"],
  Colorado: ["Longmont"],
  Florida: [
    "Sanford",
    "Fort Myers",
    "Jacksonville",
    "Rivera Beach",
    "Tampa",
    "Springfield",
    "Miami",
    "South Daytona",
    "Winter Garden",
    "Sarasota",
    "Ocala",
    "Lake City",
    "Elkton",
    "Pompano Beach",
    "Kissimmee",
  ],
  Georgia: ["Palmetto", "Lawrenceville", "White", "Augusta", "Lake Park"],
  Kansas: ["Kansas City"],
  Kentucky: ["Franklin"],
  Maryland: ["Upper Marlboro"],
  "North Carolina": [
    "Asheville",
    "Greensboro",
    "Raleigh",
    "Fayetteville",
    "Charlotte",
    "Wilmington",
  ],
  Oklahoma: ["Tulsa"],
  "South Carolina": [
    "Lexington",
    "Sumter",
    "Columbia",
    "Myrtle Beach",
    "Greenville",
    "Rock Hill",
    "North Charleston",
  ],
  Tennessee: ["Nashville"],
  Texas: [
    "Balch Springs",
    "Prosper",
    "Round Rock",
    "Friendswood",
    "Hewitt",
    "Magnolia",
    "Haltom City",
  ],
  Virginia: [
    "Richmond",
    "Chesapeake",
    "Haymarket",
    "Charlottesville",
    "Fredericksburg",
    "Newport News",
    "Mount Crawford",
    "Danville",
  ],
};

const docsByMunicipality: { [key: string]: Document[] } = Object.fromEntries(
  Object.entries(usStates).flatMap(([_, municipalities]) =>
    municipalities.map((municipality) => [
      municipality,
      [
        {
          name: "Public Works Manual Design and Construction Part 1 Miami",
          type: "pdf",
          value: "pdfs/standard-details-1a.pdf",
        },
        {
          name: "Public Works Manual Design and Construction Part 2 Miami",
          type: "pdf",
          value: "pdfs/standard-details-2a.pdf",
        },
        {
          name: "Section D7 Sanitary Sewage Collection and Disposal a Portion of Part 2 - Public Works Manual",
          type: "pdf",
          value: "pdfs/standard-details-2b.pdf",
        },
        {
          name: "Three Chambers (3C)",
          type: "pdf",
          value:
            "pdfs/Sh1-4-Pollution-Control-Structure-Three-Chambers-Layout1.pdf",
        },
        {
          name: "Precast Catch Basin Miami",
          type: "pdf",
          value:
            "pdfs/Sh2-2-Precast-Catch-Basin-Type-D-3-8in-slab-Reinforcement-Table-Layout-2.pdf",
        },
        {
          name: "Slide Grate Detail Miami",
          type: "pdf",
          value: "pdfs/Sh1-4-Manatee-Grate-Layout1.pdf",
        },
        {
          name: "Standard Details Part 2A",
          type: "pdf",
          value: "pdfs/Sh1-4-Inlet-Manhole-Junction-Box-Type-J-7T-Layout1.pdf",
        },
        {
          name: "Engineering Details Miami-Dade County",
          type: "link",
          value:
            "https://www.miamidade.gov/global/transportation/public-works/engineering-details.page",
        },
        {
          name: "Miami-Dade County Home Page",
          type: "link",
          value: "https://www.miamidade.gov/global/home.page",
        },
      ],
    ])
  )
);

const Sidebar = () => {
  const [currentState, setCurrentState] = useState<string>("");
  const [currentMunicipality, setCurrentMunicipality] = useState<string>("");
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [showCustomStateInput, setShowCustomStateInput] = useState(false);
  const [showCustomMunicipalityInput, setShowCustomMunicipalityInput] =
    useState(false);
  const [customState, setCustomState] = useState<string>("");
  const [customMunicipality, setCustomMunicipality] = useState<string>("");

  const setPdfSelected = useMainStore((state: any) => state.updatePdfSelected);

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    const state = event.target.value;
    setCurrentMunicipality("");
    setCurrentState(state);
    if (state === "missingState") {
      setShowCustomStateInput(true);
      setCustomState("");
      setCustomMunicipality("");
    } else {
      setSelectedPdf(null);
      setShowCustomStateInput(false);
      setShowCustomMunicipalityInput(false);
    }
  };

  const handleMunicipalityChange = (event: SelectChangeEvent<string>) => {
    const municipality = event.target.value;
    setCurrentMunicipality(municipality);
    if (municipality === "missingMunicipality") {
      setShowCustomMunicipalityInput(true);
      setCustomMunicipality("");
    } else {
      setSelectedPdf(null);
      setShowCustomMunicipalityInput(false);
    }
  };

  const handlePdfClick = (pdf: string, value: string) => {
    setSelectedPdf(pdf);
    setPdfSelected(value);
  };

  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  const formComponent = (isNewState: boolean) => {
    return (
      <div className="flex flex-col items-end">
        {isNewState && (
          <TextField
            label="Enter State"
            fullWidth
            value={customState}
            onChange={(e) => setCustomState(e.target.value)}
            sx={{ mb: 2 }}
          />
        )}
        <TextField
          label="Enter Municipality"
          fullWidth
          value={customMunicipality}
          onChange={(e) => setCustomMunicipality(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          disabled={(isNewState && !customState) || !customMunicipality}
          onClick={() => {
            setShowCustomStateInput(false);
            setShowCustomMunicipalityInput(false);
            showCustomStateInput && setCurrentState("");
            setCurrentMunicipality("");
          }}
        >
          Submit
        </Button>
      </div>
    );
  };

  return (
    <div className="sticky top-[calc(64px+10px)] h-full overflow-y-auto w-64">
      <div className="p-4">
        <Select
          value={currentState}
          displayEmpty
          onChange={handleStateChange}
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>
            Select a State
          </MenuItem>
          {Object.keys(usStates).map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
          <MenuItem value="missingState">Missing State?</MenuItem>
        </Select>

        {showCustomStateInput && formComponent(true)}

        {!showCustomStateInput && (
          <Select
            value={currentMunicipality}
            displayEmpty
            onChange={handleMunicipalityChange}
            fullWidth
            disabled={currentState === "" || showCustomStateInput}
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>
              Select a Municipality
            </MenuItem>
            {currentState &&
              usStates[currentState]?.map((municipality) => (
                <MenuItem key={municipality} value={municipality}>
                  {municipality}
                </MenuItem>
              ))}
            <MenuItem value="missingMunicipality">
              Missing Municipality?
            </MenuItem>
          </Select>
        )}

        {!showCustomStateInput &&
          showCustomMunicipalityInput &&
          formComponent(false)}

        {currentMunicipality && (
          <>
            {/* PDF List */}
            <ThemeProvider theme={theme}>
              <List>
                {docsByMunicipality[currentMunicipality]?.map((item, index) => (
                  <CustomTooltip
                    title={item.name.length >= 18 ? item.name : ""}
                    placement="top-end"
                  >
                    <ListItemButton
                      key={index}
                      sx={{
                        bgcolor:
                          selectedPdf === item.name
                            ? "primary.light"
                            : "primary.main",
                        color: "white",
                        "&:hover": {
                          bgcolor: "primary.light",
                        },
                      }}
                      onClick={() =>
                        item.type === "pdf"
                          ? handlePdfClick(item.name, item.value)
                          : openLinkInNewTab(item.value)
                      }
                    >
                      <ListItemIcon sx={{ color: "white", minWidth: 35 }}>
                        {item.type === "pdf" ? <PictureAsPdf /> : <OpenInNew />}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          style: {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          },
                        }}
                      />
                    </ListItemButton>
                  </CustomTooltip>
                ))}
              </List>
            </ThemeProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
