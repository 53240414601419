import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import Comment from "../Comment/Comment";
import { CommentData, useMainStore } from "../../Zustand/main.store";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";

const CommentBox = () => {
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Submit");
  };

  const handleChangeName= (event: any) => {
    console.log(event.target)
    setName(event.target.value)
  };

  const handleChangeComment= (event: any) => {
    setComment(event.target.value)
  };

  const commentsList: CommentData[] =
    useMainStore((state: any) => state.comments) ?? [];

  return (
    <div className="w-[95%] mx-auto">
      <hr className="mb-10" />
      <Accordion>
        <AccordionSummary
          aria-controls="panel1-content"
          expandIcon={<ExpandMore />}
          id="panel1-header"
        >
          <h2 className="font-bold">Add a Comment</h2>
        </AccordionSummary>
        <AccordionDetails>
          <form
            className="flex flex-col gap-5"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <TextField
              className="w-[70%]"
              error={false}
              id="name"
              label="Name"
              value={name}
              required
              helperText=""
              onChange={handleChangeName}
            />
            <TextField
              className="w-[70%]"
              id="comment"
              label="Comment"
              placeholder="Add a comment..."
              name="comment"
              value={comment}
              multiline
              rows={4}
              inputProps={{ maxLength: 300 }}
              required
              variant="standard"
              helperText={`${comment.length}/300`}
              onChange={handleChangeComment}
            />
            <button className="bg-blue-500 text-white font-bold py-2 px-5 roundeds w-[200px]"
                    disabled={name === "" || comment === ""}>
              Comment
            </button>
          </form>
        </AccordionDetails>
      </Accordion>

      <div className="flex flex-col text-end mt-5">
        <p>4 Comment(s) </p>
        <hr className="mt-2" />
      </div>
      {/* Comments */}
      <div className="w-full h-[300px] overflow-auto mt-5">
        <Comment text={commentsList[0].description} />
        <Comment text={commentsList[1].description} />
        <Comment text={commentsList[2].description} />
        <Comment text={commentsList[2].description} />
      </div>
    </div>
  );
};

export default CommentBox;
