import { create } from "zustand";

export interface CommentData {
  id: string;
  name: string;
  description: string;
  timeStamp: string;
}

export const useMainStore = create((set) => ({
  comments: [
    {
      description:
        "The material standards for the reclaimed water system in Orlando include the use of PVC and ductile iron pipes, compliant fittings, and appropriate bedding materials. Protective coatings are required for corrosion resistance, and signage must clearly indicate reclaimed water use. All materials must meet local, state, and federal regulations to ensure safety and system reliability. For detailed specifications, refer to the document.",
      name: "Eduardo Sanchez",
      id: "1",
      timeStamp: "",
    },
    {
      description:
        "In Orlando, signage for reclaimed water systems must clearly indicate the use of reclaimed water. Signs should be prominently displayed, using specific colors (typically purple) and wording to alert the public. They must comply with state regulations to ensure safety and awareness, preventing accidental misuse of reclaimed water.",
      name: "Eduardo Sanchez",
      id: "2",
      timeStamp: "",
    },
    {
      description:
        "In Orlando, protective coatings for pipes and fittings must be corrosion-resistant, suitable for reclaimed water, and comply with local standards to ensure durability and extend service life",
      name: "Eduardo Sanchez",
      id: "3",
      timeStamp: "",
    },
  ],
  pdfSelected: "",
  updateComments: (comments: Comment[]) => set({ comments: [...comments] }),
  updatePdfSelected: (value: string) => set({ pdfSelected: value }),
}));
